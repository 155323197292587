// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-activities-index-js": () => import("./../../../src/pages/activities/index.js" /* webpackChunkName: "component---src-pages-activities-index-js" */),
  "component---src-pages-components-layout-js": () => import("./../../../src/pages/components/Layout.js" /* webpackChunkName: "component---src-pages-components-layout-js" */),
  "component---src-pages-components-nav-js": () => import("./../../../src/pages/components/Nav.js" /* webpackChunkName: "component---src-pages-components-nav-js" */),
  "component---src-pages-components-seo-js": () => import("./../../../src/pages/components/SEO.js" /* webpackChunkName: "component---src-pages-components-seo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-activity-template-js": () => import("./../../../src/templates/activity-template.js" /* webpackChunkName: "component---src-templates-activity-template-js" */),
  "component---src-templates-lesson-list-template-js": () => import("./../../../src/templates/lesson-list-template.js" /* webpackChunkName: "component---src-templates-lesson-list-template-js" */),
  "component---src-templates-month-template-js": () => import("./../../../src/templates/month-template.js" /* webpackChunkName: "component---src-templates-month-template-js" */)
}

